import React from "react";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

// Pages
import InterestForm from "./pages/InterestForm/InterestForm";
import DevInterestForm from "./pages/DevInterestForm/DevInterestForm";

// Firebase configuration
const firebaseConfig = {
  apiKey:
    process.env.REACT_APP_FIREBASE_API_KEY || "any-default-local-build_env",
  authDomain:
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "any-default-local-build_env",
  projectId: process.env.REACT_APP_PROJECT_ID || "any-default-local-build_env",
  storageBucket:
    process.env.REACT_APP_STORAGE_BUCKET || "any-default-local-build_env",
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ||
    "any-default-local-build_env",
  appId: process.env.REACT_APP_APP_ID || "any-default-local-build_env",
  measurementId:
    process.env.REACT_APP_MEASUREMENT_ID || "any-default-local-build_env",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Page routes
const routes = [
  { path: "/", component: <InterestForm db={db} /> }, // TODO: Should this be a redirect to /jobs?
  { path: "/jobs", component: <DevInterestForm db={db} /> },
];

class App extends React.Component {
  render = () => {
    return (
      <BrowserRouter>
        <Routes>
          {routes.map((route) => (
            <Route path={route.path} element={route.component} />
          ))}
        </Routes>
      </BrowserRouter>
    );
  };
}

export default App;
