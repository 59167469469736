import React, { useState, useEffect, startTransition } from "react";
import ReactMarkdown from "react-markdown";
import {
  Button,
  Checkbox,
  ConfigProvider,
  Form,
  Input,
  Modal,
  Col,
  Row,
  Select,
  notification,
} from "antd";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { CheckCircleFilled } from "@ant-design/icons";
import { collection, addDoc } from "firebase/firestore";
import "./InterestForm.css";
import PrivacyPolicyMD from "./../../PrivacyPolicy.md";

// Form components
const { TextArea } = Input;
const { Option } = Select;

const LeftContainer = ({ db, policy }) => {
  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] =
    useState(false);

  const openPrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(true);
  };

  const closePrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(false);
  };

  return (
    <div className={isMobile ? "left-container-mobile" : "left-container"}>
      <div className="logo-and-trademark">
        <img className="logo" src="../images/decert_logo.png" alt="logo" />
        <div className="blue-color">TM</div>
      </div>

      <div className={isMobile ? "center-mobile" : "center"}>
        <p>
          Helping companies hire{" "}
          <span style={{ color: "#4456D6", fontStyle: "italic" }}>better</span>{" "}
          developers,{" "}
          <span style={{ color: "#3677DF", fontStyle: "italic" }}>faster.</span>
        </p>
      </div>

      <div className={isMobile ? "subtext-mobile" : "subtext"}>
        <p>Coming soon.</p>
      </div>

      <div>
        <script
          src="https://platform.linkedin.com/in.js"
          type="text/javascript"
        >
          {" "}
          lang: en_US
        </script>
        <script
          type="IN/FollowCompany"
          data-id="97194521"
          data-counter="right"
        ></script>
      </div>

      <BrowserView>
        <div className="privacy-policy">
          <div className="grey-link" onClick={openPrivacyPolicyModal}>
            Privacy Policy
          </div>
          <Modal
            title="DeCert Privacy Policy"
            open={isPrivacyPolicyModalOpen}
            onCancel={closePrivacyPolicyModal}
            footer={null}
            width={0.5 * window.innerWidth}
          >
            <div className="privacy-policy-modal-text">
              <ReactMarkdown width>{policy}</ReactMarkdown>
            </div>
          </Modal>
        </div>
      </BrowserView>
    </div>
  );
};

const RightContainer = ({ db, policy }) => {
  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] =
    useState(false);

  const openPrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(true);
  };

  const closePrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(false);
  };

  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const openNotification = () => {
    api.open({
      message: "Thank you!",
      description: "We'll be in touch soon.",
      icon: (
        <CheckCircleFilled
          style={{
            color: "green",
          }}
        />
      ),
      placement: "bottomRight",
    });
  };

  useEffect(() => {
    resetForm();
  }, [form]);

  const resetForm = () => {
    form.setFieldsValue({
      work: [],
      firstName: "",
      lastName: "",
      email: "",
      affiliation: "",
      interestType: "schedule_chat",
      questions: "",
      isEmailOptIn: false,
    });
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const data = values;
        data["timestamp"] = new Date();

        addDoc(collection(db, "interest-form-responses"), data)
          .then((docRef) => {
            openNotification();
            resetForm();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={isMobile ? "right-container-mobile" : "right-container"}>
      {contextHolder}
      <ConfigProvider
        theme={{
          token: {
            fontSize: 15,
          },
        }}
      >
        <Form
          form={form}
          name="interest-form"
          layout="vertical"
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <BrowserView>
            <Form.Item
              label={
                <div>
                  Are you a...{" "}
                  <span style={{ color: "grey", fontStyle: "italic" }}>
                    (select all that apply)
                  </span>
                </div>
              }
              name="work"
              rules={[{ required: true, message: "This field is required." }]}
            >
              <Checkbox.Group style={{ width: "100%" }} name="work">
                <Row>
                  <Col span={20}>
                    <Checkbox value="accelerator">Accelerator</Checkbox>
                  </Col>
                  <Col span={20}>
                    <Checkbox value="vc">VC partner</Checkbox>
                  </Col>
                  <Col span={20}>
                    <Checkbox value="company">Company</Checkbox>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <Checkbox value="developer">Developer</Checkbox>
                  </Col>
                  <Col span={20}>
                    <Checkbox value="recruitment">
                      Working in recruitment
                    </Checkbox>
                  </Col>
                  <Col span={20}>
                    <Checkbox value="other">
                      Other (e.g. student, faculty)
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </BrowserView>

          <MobileView>
            <Form.Item
              label={
                <div>
                  Are you a...{" "}
                  <span style={{ color: "grey", fontStyle: "italic" }}>
                    (select all that apply)
                  </span>
                </div>
              }
              name="work"
              rules={[{ required: true, message: "This field is required." }]}
            >
              <Checkbox.Group style={{ width: "100%" }} name="work">
                <Col span={20}>
                  <Checkbox value="accelerator">Accelerator</Checkbox>
                </Col>
                <Col span={20}>
                  <Checkbox value="vc">VC partner</Checkbox>
                </Col>
                <Col span={20}>
                  <Checkbox value="company">Company</Checkbox>
                </Col>

                <Col span={20}>
                  <Checkbox value="developer">Developer</Checkbox>
                </Col>
                <Col span={20}>
                  <Checkbox value="recruitment">
                    Working in recruitment
                  </Checkbox>
                </Col>
                <Col span={20}>
                  <Checkbox value="other">
                    Other (e.g. student, faculty)
                  </Checkbox>
                </Col>
              </Checkbox.Group>
            </Form.Item>
          </MobileView>

          <div
            className={
              isMobile ? "left-right-container-mobile" : "left-right-container"
            }
          >
            <div className="left-right-container-item-flex-1">
              <Form.Item
                label="First name"
                name="firstName"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input placeholder="First name" />
              </Form.Item>
            </div>

            <div className="left-right-container-item-flex-1">
              <Form.Item
                label="Last name"
                name="lastName"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input placeholder="Last name" />
              </Form.Item>
            </div>
          </div>

          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            label="Affiliation"
            name="affiliation"
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Input placeholder="E.g. name of your university, company, organization" />
          </Form.Item>

          <Form.Item
            label="Are you interested in building DeCert with us?"
            name="interestType"
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Select placeholder="Select a option and change input text above">
              <Option value="schedule_chat">Yes, let’s schedule a chat.</Option>
              <Option value="pilot_test">
                No, reach out to me only when you’re pilot testing.
              </Option>
              <Option value="when_launched">
                No, just let me know when you’ve launched.
              </Option>
            </Select>
          </Form.Item>

          <Form.Item label="Any questions or comments?" name="questions">
            <TextArea rows={2} />
          </Form.Item>

          <div
            className={
              isMobile ? "submit-container-mobile" : "submit-container"
            }
          >
            <Form.Item name="isEmailOptIn" valuePropName="checked">
              <Checkbox>
                I would like to receive occasional email updates.
              </Checkbox>
            </Form.Item>
            <Button
              type="primary"
              style={{ background: "#4456D6" }}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        </Form>

        <div>
          <p
            className={isMobile ? "disclaimer-text-mobile" : "disclaimer-text"}
          >
            By submitting this form, you understand and agree that DeCert may
            contact you via your provided email address and use your information
            in accordance to its Privacy Policy.
          </p>
        </div>
        <MobileView>
          <div className="privacy-policy-mobile">
            <div className="grey-link" onClick={openPrivacyPolicyModal}>
              Privacy Policy
            </div>
            <Modal
              title="DeCert Privacy Policy"
              open={isPrivacyPolicyModalOpen}
              onCancel={closePrivacyPolicyModal}
              footer={null}
              width={0.8 * window.innerWidth}
            >
              <div className="privacy-policy-modal-text">
                <ReactMarkdown width>{policy}</ReactMarkdown>
              </div>
            </Modal>
          </div>
        </MobileView>
      </ConfigProvider>
    </div>
  );
};

const InterestForm = ({ db }) => {
  const [policy, setPolicy] = useState("");

  useEffect(() => {
    fetch(PrivacyPolicyMD)
      .then((response) => response.text())
      .then((text) => {
        setPolicy(text);
      });
  }, []);

  return (
    <div className={isMobile ? "main-container-mobile" : "main-container"}>
      <LeftContainer db={db} policy={policy} />
      <RightContainer db={db} policy={policy} />
    </div>
  );
};

export default InterestForm;
