import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import {
  Button,
  Checkbox,
  ConfigProvider,
  Form,
  Input,
  Modal,
  Col,
  Row,
  Select,
  notification,
} from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  increment,
} from "firebase/firestore";
import { useSearchParams } from "react-router-dom";
import { BrowserView, MobileView, isMobile } from "react-device-detect";

import "./DevInterestForm.css";
import PrivacyPolicyMD from "./../../PrivacyPolicy.md";

// Form components
const { TextArea } = Input;
const { Option } = Select;

const LeftContainer = ({ db, policy }) => {
  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] =
    useState(false);

  const openPrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(true);
  };

  const closePrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(false);
  };

  return (
    <div className={isMobile ? "left-container-mobile" : "left-container"}>
      <div className="logo-and-trademark">
        <img className="logo" src="../images/decert_logo.png" alt="logo" />
        <div className="blue-color">TM</div>
      </div>

      <div className={isMobile ? "center-mobile" : "center"}>
        <p>
          Helping companies hire{" "}
          <span style={{ color: "#4456D6", fontStyle: "italic" }}>better</span>{" "}
          developers,{" "}
          <span style={{ color: "#3677DF", fontStyle: "italic" }}>faster.</span>
        </p>
      </div>

      <div className="description-header blue-color">What is DeCert?</div>

      <div>
        <p>
          Tech hiring today is a mess. It's the same old cycle of uploading your
          resume, grinding coding problems, and still getting rejected. Why does
          it have to be so inefficient? Why does it have to be this hard?
        </p>
        <p>
          DeCert is a new way for developers to get hired. We help companies
          find developers with the right skills, and help developers get hired
          based on their skills.
        </p>
      </div>

      <div className="description-header blue-color">Why DeCert?</div>

      <div>
        <p>
          We've heard you. You're sick of applying to so many jobs every and
          never hearing back. You're tired of doing so many of the same coding
          tests. You're frustrated that these coding tests don't even showcase
          the skills you have.
        </p>
        <p>
          DeCert addresses all of these pain points. We help you get hired based
          on your skills and preferences. And you only do each certification
          test once. Just once.
        </p>
      </div>

      <div className="description-header blue-color">How does DeCert work?</div>

      <div>
        <p>
          <span style={{ color: "#4456D6", fontWeight: "bold" }}>Step 1:</span>{" "}
          Build your developer profile and state your preferences.
          <br />
          <span style={{ color: "#4456D6", fontWeight: "bold" }}>
            Step 2:
          </span>{" "}
          Complete certification tests for each of your skills.
          <br />
          <span style={{ color: "#4456D6", fontWeight: "bold" }}>
            Step 3:
          </span>{" "}
          Sit back, relax and wait for an interview.
        </p>
      </div>

      <div className={isMobile ? "subtext-mobile" : "subtext"}>
        <p>
          We're launching a pilot test in mid-2024, focusing on certifying data
          science skills and getting you a data science job.
        </p>
      </div>

      <div>
        <script
          src="https://platform.linkedin.com/in.js"
          type="text/javascript"
        >
          {" "}
          lang: en_US
        </script>
        <script
          type="IN/FollowCompany"
          data-id="97194521"
          data-counter="right"
        ></script>
      </div>

      <BrowserView>
        <div className="privacy-policy">
          <div className="grey-link" onClick={openPrivacyPolicyModal}>
            Privacy Policy
          </div>
          <Modal
            title="DeCert Privacy Policy"
            open={isPrivacyPolicyModalOpen}
            onCancel={closePrivacyPolicyModal}
            footer={null}
            width={0.5 * window.innerWidth}
          >
            <div className="privacy-policy-modal-text">
              <ReactMarkdown width>{policy}</ReactMarkdown>
            </div>
          </Modal>
        </div>
      </BrowserView>
    </div>
  );
};

const RightContainer = ({ db, policy, posterId }) => {
  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] =
    useState(false);

  const openPrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(true);
  };

  const closePrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(false);
  };

  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const openNotification = () => {
    api.open({
      message: "Thank you!",
      description: "Hang tight! We'll be in touch soon.",
      icon: (
        <CheckCircleFilled
          style={{
            color: "green",
          }}
        />
      ),
      placement: "bottomRight",
    });
  };

  useEffect(() => {
    resetForm();
  }, [form]);

  const resetForm = () => {
    form.setFieldsValue({
      firstName: "",
      lastName: "",
      email: "",
      affiliation: "",
      linkedin: "",
      github: "",
      questions: "",
    });
  };

  const onSubmit = (posterId) => {
    form
      .validateFields()
      .then((values) => {
        const data = values;
        data["timestamp"] = new Date();

        addDoc(collection(db, "dev-interest"), data)
          .then((docRef) => {
            // Add to poster's completion count
            const incrementPosterCompletionCount = async () => {
              await updateDoc(
                doc(db, "dev-interest-stats", posterId.toString()),
                {
                  completions: increment(1),
                }
              ).catch((error) => {
                console.log(error);
              });
            };

            if (posterId) {
              incrementPosterCompletionCount();
            }

            openNotification();
            resetForm();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={isMobile ? "right-container-mobile" : "right-container"}>
      {contextHolder}
      <ConfigProvider
        theme={{
          token: {
            fontSize: 15,
          },
        }}
      >
        <div
          className={
            isMobile
              ? "title blue-color center-mobile"
              : "title blue-color center"
          }
        >
          Sign up for the pilot test now!
        </div>
        <Form
          form={form}
          name="interest-form"
          layout="vertical"
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <div
            className={
              isMobile ? "left-right-container-mobile" : "left-right-container"
            }
          >
            <div className="left-right-container-item-flex-1">
              <Form.Item
                label="First name"
                name="firstName"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input placeholder="First name" />
              </Form.Item>
            </div>

            <div className="left-right-container-item-flex-1">
              <Form.Item
                label="Last name"
                name="lastName"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input placeholder="Last name" />
              </Form.Item>
            </div>
          </div>

          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            label="Affiliation"
            name="affiliation"
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Input placeholder="E.g. name of your university, company or organization" />
          </Form.Item>

          <div
            className={
              isMobile ? "left-right-container-mobile" : "left-right-container"
            }
          >
            <div className="left-right-container-item-flex-1">
              <Form.Item label="LinkedIn username" name="linkedin">
                <Input placeholder="www.linkedin.com/in/..." />
              </Form.Item>
            </div>

            <div className="left-right-container-item-flex-1">
              <Form.Item label="GitHub username" name="github">
                <Input placeholder="www.github.com/..." />
              </Form.Item>
            </div>
          </div>

          <Form.Item label="Any questions or comments?" name="questions">
            <TextArea rows={2} />
          </Form.Item>

          <div
            className={
              isMobile ? "submit-container-mobile" : "submit-container"
            }
          >
            <Button
              type="primary"
              style={{ background: "#4456D6" }}
              onClick={() => {
                onSubmit(posterId);
              }}
            >
              Sign up for pilot test
            </Button>
          </div>
        </Form>

        <div>
          <p
            className={isMobile ? "disclaimer-text-mobile" : "disclaimer-text"}
          >
            By submitting this form, you understand and agree that DeCert may
            contact you via your provided email address and use your information
            in accordance to its Privacy Policy.
          </p>
        </div>

        <MobileView>
          <div className="privacy-policy-mobile">
            <div className="grey-link" onClick={openPrivacyPolicyModal}>
              Privacy Policy
            </div>
            <Modal
              title="DeCert Privacy Policy"
              open={isPrivacyPolicyModalOpen}
              onCancel={closePrivacyPolicyModal}
              footer={null}
              width={0.8 * window.innerWidth}
            >
              <div className="privacy-policy-modal-text">
                <ReactMarkdown width>{policy}</ReactMarkdown>
              </div>
            </Modal>
          </div>
        </MobileView>
      </ConfigProvider>
    </div>
  );
};

const DevInterestForm = ({ db }) => {
  const [policy, setPolicy] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const posterId = searchParams.get("posterId");
  // if (posterId) {
  //   console.log("Poster ID: " + posterId);
  // }

  useEffect(() => {
    const incrementVisitCount = async () => {
      await updateDoc(doc(db, "dev-interest-stats", posterId.toString()), {
        visits: increment(1),
      })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    };

    if (posterId) {
      // TODO: Figure out why posterId is updated twice (+2 instead of +1)
      incrementVisitCount();
    }
  }, [posterId, db]);

  useEffect(() => {
    fetch(PrivacyPolicyMD)
      .then((response) => response.text())
      .then((text) => {
        setPolicy(text);
      });
  }, []);

  return (
    <div className={isMobile ? "main-container-mobile" : "main-container"}>
      <LeftContainer db={db} policy={policy} />
      <RightContainer db={db} policy={policy} posterId={posterId} />
    </div>
  );
};

export default DevInterestForm;
